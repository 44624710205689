<template>
  <div class="error__page">
    <div class="container">
      <div class="error__page-inner">
        <div class="error__page-content">
          <h1 class="error__page-title">{{ $t("notFound") }}</h1>
          <p class="error__page-subtitle">
            {{ $t("unfortunately") }}
          </p>
          <button class="error__page-btn" @click="$router.go(-1)">
            <svg
              width="9"
              height="18"
              viewBox="0 0 9 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 16.3909L7.28663 18L0.5 9L7.28663 0L8.5 1.60909L2.92674 9L8.5 16.3909Z"
                fill="#2D2F32"
              />
            </svg>
            {{ $t("back") }}
          </button>
        </div>
        <img class="error__page-img" src="@/assets/img/icons/404.png" alt="" /> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style lang="scss" scoped></style>
